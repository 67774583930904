import { useAccount } from '../auth/hooks/useAccount'
import { LazyRefreshIntegrationData } from './atoms/RefreshIntegrationData/LazyRefreshIntegrationData'
import { LazyTimeZoneTracker } from './atoms/TimeZoneTracker/LazyTimeZoneTracker'
import dynamic from 'next/dynamic'
import { useConditionHasBeenMetForXTime } from '../../hooks/useConditionHasBeenMetForXTime'
import { useFeatureFlag } from '../auth/hooks/useFeatureFlag'
import { featureFlags } from '../auth/FeatureFlagProvider'
import { StringParam, useQueryParam } from 'next-query-params'
import { circleInviteQueryParamName } from '../../common/constants/queryParams'
import { isNewUserSessionStorageKey } from '../../common/constants/sessionStorage'

const LazyCircleInviteFlowKickoffMonitor = dynamic(() =>
  import('../circles/atoms/CircleInviteFlowKickoffMonitor').then((x) => x.CircleInviteFlowKickoffMonitor)
)

const LazyForceProfileCompletionAndTosAcceptance = dynamic(() =>
  import('../modal/organisms/ForceProfileCompletionAndTosAcceptance').then(
    (x) => x.ForceProfileCompletionAndTosAcceptance
  )
)

const LazyMonitorNewAchievements = dynamic(() =>
  import('./atoms/MonitorNewAchievements').then((x) => x.MonitorNewAchievements)
)

const LazyMonitorTasks = dynamic(() => import('./atoms/MonitorTasks').then((x) => x.MonitorTasks))

export function MonitoringAndSyncing() {
  const { isAuthenticated } = useAccount()
  const isAchievementNotificationsEnabled = useFeatureFlag(featureFlags.achievement_notifications).isEnabled

  // the MonitorNewAchievements component is a bit heavy. wait a little after auth until mounting
  const mountMoreComponents = useConditionHasBeenMetForXTime({
    condition: isAchievementNotificationsEnabled && isAuthenticated,
    wait: 5000,
  })

  const [circleInviteQueryParam] = useQueryParam(circleInviteQueryParamName, StringParam)

  const checkTimeZone = typeof window !== 'undefined' && !sessionStorage.getItem(isNewUserSessionStorageKey)
  return (
    <>
      {circleInviteQueryParam && <LazyCircleInviteFlowKickoffMonitor />}
      {isAuthenticated && (
        <>
          <LazyMonitorTasks />
          {mountMoreComponents && <LazyForceProfileCompletionAndTosAcceptance />}
          {checkTimeZone && <LazyTimeZoneTracker />}
          <LazyRefreshIntegrationData />
          {mountMoreComponents && <LazyMonitorNewAchievements />}
        </>
      )}
    </>
  )
}
