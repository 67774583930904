import * as React from 'react'

import { useRouter } from 'next/router'
import { getQueryParamString } from '../../../utilities/getQueryParamString'
import { useSetModal } from '../../modal/recoil'
import dynamic from 'next/dynamic'

/* LANDING URL
https://app.upgradehealth.com/?supportSignUp=true&supportForgotPassword=true&message=Your%20email%20was%20verified.%20You%20can%20continue%20using%20the%20application.&success=true&code=success
*/

const LazyEmailVerificationModal = dynamic(() =>
  import('../atoms/EagerEmailVerificationModal').then((x) => x.EagerEmailVerificationModal)
)

export function useShowEmailVerificationModal() {
  const { query } = useRouter()

  const setModal = useSetModal()

  React.useEffect(() => {
    const message = getQueryParamString(query.message)
    const isEmailVerification = message.includes('verified') && message.includes('email')
    if (isEmailVerification) {
      setModal(<LazyEmailVerificationModal />)
    }
    return () => {}
  }, [query.message, setModal])

  return <>{null}</>
}
