import React from 'react'
import jsCookie from 'js-cookie' // eslint-disable-line no-restricted-imports
import { useInterval } from './useInterval'
import { isEqual } from 'lodash'

type Cookies = Record<string, string>
type Context = ({ cookies: Cookies } & Pick<typeof jsCookie, 'set' | 'remove'>) | undefined

export function parseCookieString(cookieString: string = '') {
  const cookies: Cookies = {}
  cookieString.split(';').forEach((cookie) => {
    const [key, value] = cookie.split('=')
    const trimmed = key?.trim()
    if (trimmed && value) {
      cookies[trimmed] = decodeURIComponent(value)
    }
  })
  return cookies
}

const CookieContext = React.createContext<Context>(undefined)

function CookieProvider({ children, cookieString }: React.PropsWithChildren<{ cookieString: string }>) {
  const [cookies, setCookies] = React.useState(parseCookieString(cookieString))

  const cookieFunctions = React.useMemo(() => {
    return {
      set: (...input: Parameters<(typeof jsCookie)['set']>) => {
        const res = jsCookie.set(...input)
        setCookies(jsCookie.get())
        return res
      },
      remove: (...input: Parameters<(typeof jsCookie)['remove']>) => {
        const res = jsCookie.remove(...input)
        setCookies(jsCookie.get())
        return res
      },
    }
  }, [])
  useInterval(() => {
    const newCookies = jsCookie.get()
    if (!isEqual(newCookies, cookies)) {
      setCookies(newCookies)
    }
  }, 300)

  if (typeof window !== 'undefined') window.cookieFunctions = cookieFunctions
  return <CookieContext.Provider value={{ cookies, ...cookieFunctions }}>{children}</CookieContext.Provider>
}

const useCookies = () => {
  const cookieContext = React.useContext(CookieContext)

  if (cookieContext === undefined) throw new Error("'useCookies' must be used within a CookieProvider")

  return cookieContext
}

export { CookieProvider, useCookies }
