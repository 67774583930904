import * as React from 'react'
import { useIsUpgradeLabsUser } from '../hooks/useIsUpgradeLabsUser'
import { realizeColorsThemeObject, upgradeLabsColorsThemeObject } from '../../../styles/theme'
import { typesafeEntries } from '../../../typescript/guards/typesafeEntries'

export function SyncThemeOnClientSideChange() {
  const { isUpgradeLabsUser } = useIsUpgradeLabsUser()
  React.useEffect(() => {
    const relevantPropertyObject = isUpgradeLabsUser ? upgradeLabsColorsThemeObject : realizeColorsThemeObject
    typesafeEntries(relevantPropertyObject).forEach(([k, v]) => document.documentElement.style.setProperty(k, v))
    return () => {}
  }, [isUpgradeLabsUser])

  return null
}
