import { useRouter } from 'next/router'
import * as React from 'react'
import { useAutoUpdatingRef } from '../../../hooks/useAutoUpdatingRef'
import { useActiveModal, useSetModal } from '../recoil'

export function useUnmountModalIfRouteChangedSinceMount({ skip }: { skip: boolean }) {
  const { pathname } = useRouter()
  const ActiveModal = useActiveModal()

  const setModal = useSetModal()

  const [pathnameOnModalMount, setPathnameOnModalMount] = React.useState(ActiveModal ? pathname : null)

  const pathnameRef = useAutoUpdatingRef(pathname)
  React.useEffect(() => {
    setPathnameOnModalMount(ActiveModal ? pathnameRef.current : null)
  }, [ActiveModal, pathnameRef])

  const ActiveModalRef = useAutoUpdatingRef(ActiveModal)

  React.useEffect(() => {
    if (pathnameOnModalMount !== pathname && !skip && ActiveModalRef.current) {
      setModal()
    }
  }, [pathnameOnModalMount, pathname, setModal, skip, ActiveModalRef])
}
