import * as React from 'react'
import { ApolloError, DocumentNode, useMutation, useQuery, useSubscription } from '@apollo/client' // eslint-disable-line no-restricted-imports
import { Role } from '../../common/constants/roles'
import { useAccount } from '../../features/auth/hooks/useAccount'
import { useToast } from '../../features/toast/useToast'
import { Sentry } from '../../features/errors/Sentry'
import { print } from 'graphql'
import { stringify } from 'flatted'
import { useReinitApolloClient } from '../client/ReinitializableApolloProvider'
import { getMainDefinition, getOperationName, isSubscriptionOperation } from '@apollo/client/utilities'

type ApolloHook = typeof useQuery | typeof useMutation | typeof useSubscription

type Options = Parameters<ApolloHook>[1]

type Input = { documentNode: DocumentNode; options: Options }
export function useAddDefaultErrorHandling({ documentNode, options = {} }: Input) {
  const { setErrorToast } = useToast()
  const requestIsSubscription = isSubscriptionOperation(documentNode)

  const [apolloReinitAttempted, setApolloReinitAttempted] = React.useState(false)

  const { reinitApolloClient } = useReinitApolloClient()
  const isRealizeEmployee = useAccount().user.hasRole(Role.realizeEmployee)

  // if data ever comes in, we want to reset the reinit attempted as we now have a working apollo subscription, so the
  // slate is clean and if a new error occurs we can still retry reinit-ing to fix it again. the flag is just to avoid
  // an infinite loop if we never successfully reconnect
  if (requestIsSubscription && apolloReinitAttempted) {
    const original = (options as Parameters<typeof useSubscription>[1])?.onData || function () {}

    ;((options as Parameters<typeof useSubscription>[1]) ?? {}).onData = (...args) => {
      setApolloReinitAttempted(false)
      return original(...args)
    }
  }

  if (!options.onError) {
    options.onError = (e: ApolloError) => {
      if (requestIsSubscription && e?.message?.toLowerCase().includes('closed')) {
        Sentry.captureException(`Subscription Error -- Error:${stringify(e, null, 2)}; Query: ${print(documentNode)}`)
        // if we have an actual subscription error, try reinitializing the client to fix it, but leave a flag so we
        // don't do this in an infinite loop if the reinit fails to solve the problem
        if (!apolloReinitAttempted) {
          setApolloReinitAttempted(true)
          reinitApolloClient()
        }
      }
      if (!isRealizeEmployee) return
      const mainDefinition = getMainDefinition(documentNode)
      const queryType =
        mainDefinition.kind === 'OperationDefinition' && 'operation' in mainDefinition ? mainDefinition.operation : ''
      setErrorToast(`Error executing ${getOperationName(documentNode)}${queryType ? ` ${queryType}` : ''}`)
    }
  }
}
