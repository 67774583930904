import { createEnumParam, useQueryParam, withDefault } from 'next-query-params'
import { packageListTypeQueryParamName } from '../../../../../common/constants/queryParams'

export enum tabs {
  upgrade = 'upgrade',
  partner = 'partner',
}

const PackageListQueryParam = withDefault(createEnumParam<tabs>([tabs.partner, tabs.upgrade]), tabs.upgrade)
export function usePackageListTypeQueryParam() {
  return useQueryParam(packageListTypeQueryParamName, PackageListQueryParam)
}
