import * as React from 'react'
import { StringParam, useQueryParams } from 'next-query-params'
import {
  errorQueryParamName,
  providerQueryParamName,
  successQueryParamName,
} from '../../../common/constants/queryParams'
import { analytics } from '../../analytics/analytics'

export function useTrackDeviceConnectResultInMixpanel() {
  const [connectQueryParams, setConnectQueryParams] = useQueryParams({
    [successQueryParamName]: StringParam,
    [providerQueryParamName]: StringParam,
    [errorQueryParamName]: StringParam,
  })

  React.useEffect(() => {
    const provider = connectQueryParams[providerQueryParamName]
    if (provider) {
      const isSuccess = connectQueryParams[successQueryParamName] === 'true'
      analytics.track(`User ${isSuccess ? '' : 'un'}successfully connected a device with ${provider}`)
      setConnectQueryParams(
        {
          [successQueryParamName]: undefined,
          [errorQueryParamName]: undefined,
          [providerQueryParamName]: undefined,
        },
        'replaceIn'
      )
    }
  }, [connectQueryParams, setConnectQueryParams])
}
