import { useRecoilState } from 'recoil'
import { sidebarOpenState } from '../recoil'
import { UnstyledButton } from '../../../common/style/UnstyledButton'
import styled from '@emotion/styled'
import { tabletDownMq } from '../../../styles/mediaQueries'
import { useSetModal } from '../../modal/recoil'
import { theme } from '../../../styles/theme'
import { transientOptions } from '../../../common/style/transientOptions'

export const hamburgerMenuId = 'hamburger-menu'

export const hamburgerContainerStyles = ({ $isActive }: { $isActive: boolean }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 48px;

  :hover {
    path, rect {
      fill: ${theme.colors.grayPorcelain};
    }
  }

  path, rect {
    transition: fill .3s;
    fill: ${$isActive ? theme.colors.grayPorcelain : theme.colors.cloud};
  }



  ${tabletDownMq} {
    padding-left: 8px;
    padding-right: 8px;
    max-width: 40px;
  }  

`

const Button = styled(UnstyledButton, transientOptions)<{ $isActive: boolean }>`
  ${hamburgerContainerStyles}
`

export const Hamburger = () => {
  const [, setIsOpen] = useRecoilState(sidebarOpenState)
  const setModal = useSetModal()

  const [sidebarOpen] = useRecoilState(sidebarOpenState)
  return (
    <Button
      id={hamburgerMenuId}
      aria-label="hamburger-menu"
      onClick={() => {
        setModal()
        setIsOpen((s) => !s)
      }}
      data-testid="hamburger"
      $isActive={sidebarOpen}
    >
      <svg width={26} height={26} viewBox="0 0 26 26" fill="none">
        <g fillRule="evenodd">
          <rect x="4" y="7" width="18" fill={theme.colors.grayPorcelain} height="2" rx="1" />
          <rect x="4" y="13" width="18" height="2" rx="1" fill={theme.colors.grayPorcelain} />
          <rect x="4" y="19" width="18" height="2" rx="1" fill={theme.colors.grayPorcelain} />
        </g>
      </svg>
    </Button>
  )
}
