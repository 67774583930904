import { Auth0Provider, useAuth0 } from '@auth0/auth0-react' // eslint-disable-line no-restricted-imports
import { StringParam, useQueryParams } from 'next-query-params'
import { useRouter } from 'next/router'
import React from 'react'
import { homeRoute, loginRoute } from '../../../common/constants/routes'
import { usePublicEnv } from '../../../hooks/usePublicEnv'
import { Auth0ExternalUsageModuleSetter } from '../../Auth0ExternalUsageModuleSetter' // eslint-disable-line no-restricted-imports
import { LoggingInContent } from '../organisms/LoggingInContent'
import { AccountProvider } from './AccountProvider'
import { useLogInState } from '../recoil'
import { v4 } from 'uuid'
import { referralSourceQueryParamName } from '../../../common/constants/queryParams'
import {
  isNewUserFirstLoggedInPageLoadSessionStorageKey,
  isNewUserSessionStorageKey,
  loginInitiatedAsSignupSessionStorageKey,
  referralSourceSessionStorageKey,
} from '../../../common/constants/sessionStorage'
import { isFirstLogIn } from '../isFirstLogIn'
import { useTrackDeviceConnectResultInMixpanel } from '../hooks/useTrackDeviceConnectResultInMixpanel'
import { FeatureFlagProvider } from '../FeatureFlagProvider'
import { sendFacebookConversionEventFromClient } from '../../analytics/sendFacebookConversionEventFromClient'
import { DEFAULT_MEMBERSHIP_TIER } from '../../account/utils/membership-tiers'
import { useCookies } from '../../../hooks/useCookies'
import { isInLogInProcessCookieName } from '../../../common/constants/cookies'
import { useShowEmailVerificationModal } from '../hooks/useShowEmailVerificationModal'
import dynamic from 'next/dynamic'

const LazySetUserTimeZoneOnInitialLogInFirstPageLoad = dynamic(() =>
  import('../atoms/EagerSetUserTimeZoneOnInitialLogInFirstPageLoad').then(
    (x) => x.EagerSetUserTimeZoneOnInitialLogInFirstPageLoad
  )
)

// !! if we ever end up in a state where we try to authenticate or log in with code/state params from auth zero in the
// !! url auth 0 throws login errors and fails to login on subsequent refreshes even after logging in if the params remain
// !! in the url. We need to make sure that 1) if we ever end up in a state where we are trying to log in, and the params
// !! exist, we strip them so they don't end up in the url we redirect the user back to (as this will cause subsequent
// !! refreshes to fail authentication), and 2) we want to make sure that after auth0 ahs processed the state/code from the
// !! url, we always strip them from there so we (hopefully) avoid ever ending up in a position where they could cause
// !! errors on refresh, login, or login + refresh.

function StripAuthParamsFromUrl() {
  const { isLoading } = useAuth0()
  const [params, setParams] = useQueryParams({ code: StringParam, state: StringParam })

  React.useEffect(() => {
    if (params.code || params.state) {
      // !!Always strip out code and state params. see note at top of file
      setParams({ code: undefined, state: undefined })
    }
    return () => {}
  }, [isLoading, params, setParams])
  return null
}

const LoginHandler = () => {
  const { asPath } = useRouter()
  const { loginWithRedirect } = useAuth0()
  const [logInState] = useLogInState()

  const { set } = useCookies()

  React.useEffect(() => {
    if (logInState.isLoggingIn) {
      const isLoginPage = asPath.split('?')[0] === loginRoute
      // default appState's postAuthRedirectUri to the current url. allow overriding this when setting loginstate
      const { appState, ...options } = logInState.loginOptions ?? {}
      // redirecting back to login after a successful login creates an infinite loop, so force '/' if we are on login page
      const unsanitizedPostAuthRedirectUri =
        appState?.postAuthRedirectUri || options.redirectUri || (isLoginPage ? homeRoute : asPath)

      // !!Always strip out code and state params. see note at top of file
      const [pathname, search] = unsanitizedPostAuthRedirectUri.split('?')
      const params = new URLSearchParams(search)
      params.delete('code')
      params.delete('state')
      // in case this is still in the url, get rid of it so it isn't in the redirect. we handle referral source in session storage and app state
      // immediately after page load and onward
      params.delete(referralSourceQueryParamName)

      const paramStringWithoutCodeAndState = params.toString()

      const sanitizedPostAuthRedirectUri = paramStringWithoutCodeAndState
        ? `${pathname}?${paramStringWithoutCodeAndState}`
        : pathname

      const willBeRedirectingPostAuth =
        sanitizedPostAuthRedirectUri.split('?')[0] !== homeRoute && !options?.redirectUri
      if (willBeRedirectingPostAuth) set(isInLogInProcessCookieName, 'true')

      const referralSource = sessionStorage.getItem(referralSourceSessionStorageKey)
      sessionStorage.removeItem(referralSourceSessionStorageKey)

      const realize_login_request_id = v4()

      // set an id for this sign in so as to determine if the user post log in is a new user or an returning user
      // send to BE in options. also send in appState so we can pull it after login and compare with the loginId that
      // comes ack in the jwt to see if this log in led to a new sign up or is just a returning user (if login request id same
      // as BE, new user)
      options.realize_login_request_id = realize_login_request_id
      loginWithRedirect({
        ...options,
        realize_signup_type: options.realize_signup_type ?? DEFAULT_MEMBERSHIP_TIER,
        appState: {
          ...appState,
          postAuthRedirectUri: sanitizedPostAuthRedirectUri,
          realize_login_request_id,
          referralSource,
          login_initiated_as_sign_up: options.screen_hint === 'signup',
        },
      })
    }
  }, [logInState, loginWithRedirect, asPath, set])
  return null
}

export const auth0scope = 'openid profile email'

export function AuthProvider({ children }: React.PropsWithChildren<{}>) {
  const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } = usePublicEnv()
  const { replace } = useRouter()
  const [{ isLoggingIn }, setLogInState] = useLogInState()
  useTrackDeviceConnectResultInMixpanel()
  useShowEmailVerificationModal()

  const { remove } = useCookies()

  const [isNewSignUpFirstPageLoad, setIsNewSignUpFirstPageLoad] = React.useState(false)
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN ?? ''}
      clientId={AUTH0_CLIENT_ID ?? ''}
      redirectUri={typeof window !== 'undefined' ? window.location.origin : ''}
      audience={AUTH0_AUDIENCE}
      scope={auth0scope}
      cacheLocation={'localstorage'}
      useRefreshTokens
      onRedirectCallback={async (appState, user) => {
        try {
          const { postAuthRedirectUri } = appState ?? {}

          if (isFirstLogIn(appState, user)) {
            // on the first page load of their first session, set their time zone
            setIsNewSignUpFirstPageLoad(true)
            sessionStorage.setItem(isNewUserSessionStorageKey, user?.sub ?? '')
            sessionStorage.setItem(isNewUserFirstLoggedInPageLoadSessionStorageKey, 'true')
            sessionStorage.setItem(
              loginInitiatedAsSignupSessionStorageKey,
              String(!!appState?.login_initiated_as_sign_up)
            )

            sendFacebookConversionEventFromClient({
              event_name: 'CompleteRegistration',
              user_data: {
                em: user?.email,
                external_id: user?.sub,
              },
            })
          }
          // Redirect to the url from state. If that is not set or if it is set to the index page, which is where we/auth0
          // redirects to natively, do nothing

          if (postAuthRedirectUri && postAuthRedirectUri !== homeRoute) {
            // we key off the cookie to immediately show the spinner, but we can follow the more synchronous
            // postAuthRedirectInProgress when that is set, so as soon as we set this to true, remove the cookie so as
            // soon as the postAuthRedirectInProgress is false, the spinner will disappear
            setLogInState((s) => ({ ...s, postAuthRedirectInProgress: true, referralSource: null }))
            remove(isInLogInProcessCookieName)
            await replace(postAuthRedirectUri)
          } else {
            setLogInState((s) => ({ ...s, referralSource: null }))
          }
        } finally {
          remove(isInLogInProcessCookieName)
          setLogInState((s) => ({ ...s, postAuthRedirectInProgress: false }))
        }
      }}
    >
      <FeatureFlagProvider>
        <AccountProvider>
          {isNewSignUpFirstPageLoad && <LazySetUserTimeZoneOnInitialLogInFirstPageLoad />}
          <StripAuthParamsFromUrl />
          <Auth0ExternalUsageModuleSetter />
          <LoginHandler />
          {isLoggingIn ? <LoggingInContent /> : children}
        </AccountProvider>
      </FeatureFlagProvider>
    </Auth0Provider>
  )
}
