import getConfig from 'next/config'
import { graphqlRouteSubdir, relayRouteSubdir } from './routes'
import { httpUrlToWebSocketUrl } from '../../utilities/httpWebsocketUrlConverters'

const { publicEnvs } = getConfig()?.publicRuntimeConfig ?? {}

// !! it seems in the new version of next, getConfig is not available app wide. thus, in this case, because this file
// has exports that are used everywhere (app, routes, node files, server, client, etc.), the getConfig call returns
// undefined sometimes. to handle this, add all versions of the env var so it works in any environment (process.env for
// build time client and node, getConfig for client runtime vars)
export const originServerUrl =
  typeof window !== 'undefined'
    ? location.origin
    : // eslint-disable-next-line regex/invalid-error
      process.env.NEXT_PUBLIC_ORIGIN_SERVER_URL ?? publicEnvs?.ORIGIN_SERVER_URL ?? ''

export const stellateCacheableHttpEndpoint =
  // eslint-disable-next-line regex/invalid-error
  process.env.NEXT_PUBLIC_STELLATE_CACHEABLE_GQL_HTTP_ENDPOINT ?? publicEnvs?.STELLATE_CACHEABLE_GQL_HTTP_ENDPOINT ?? ''

export const hasuraEndpoint =
  // eslint-disable-next-line regex/invalid-error
  process.env.NEXT_PUBLIC_HASURA_ENDPOINT ?? publicEnvs?.HASURA_ENDPOINT ?? ''
export const hasuraGqlEndpoint = `${hasuraEndpoint}${graphqlRouteSubdir}`
export const hasuraRelayEndpoint = `${hasuraEndpoint}${relayRouteSubdir}`

// CLIENT NON-ADMIN
export const clientNonAdminWsEndpoint = `${httpUrlToWebSocketUrl(hasuraGqlEndpoint)}`
// some of this seems a bit duplicative (assigning a new variable to the same value as an existing one), but would like
// to be explicit about each item's function via the name as they have tended to drift in the past and i want to be sure
// the correct item is used everywhere even if that happens
export const clientNonAdminRelayEndpoint = hasuraRelayEndpoint
export const clientNonAdminStellateCacheableGqlHttpEndpoint = stellateCacheableHttpEndpoint

// CLIENT ADMIN
export const clientAdminWsEndpoint = `${httpUrlToWebSocketUrl(originServerUrl)}${graphqlRouteSubdir}`
export const clientAdminRelayEndpoint = `${originServerUrl}${relayRouteSubdir}`
export const clientAdminGqlEndpoint = `${originServerUrl}${graphqlRouteSubdir}`

export const appendUserIdToClientAdminWsEndpoint = (userId: string) => `${clientAdminWsEndpoint}?userId=${userId}`

/**
 * @Notes
 */
/* eslint-disable regex/invalid-error */

// URL COMPONENTS

// ws()
// relaySubdir
// graphSubdir
// gqlApiEndpoint
// host

// NEXT_PUBLIC_CACHEABLE_GQL_HTTP_ENDPOINT=https://capi.dev.realizeme.io
// NEXT_PUBLIC_HASURA_ENDPOINT=https://realize-dev.hasura.app

// CLIENT
//    NON-ADMIN:
//      WS:  ws(NEXT_PUBLIC_HASURA_ENDPOINT) + graphSubdir
//      RELAY: NEXT_PUBLIC_HASURA_ENDPOINT + relaySubdir
//      GQL: NEXT_PUBLIC_CACHEABLE_GQL_HTTP_ENDPOINT

// CLIENT
//    ADMIN:
//      WS:  ws(host) + graphSubdir
//      RELAY: host + relaySubdir
//      GQL: host + graphSubdir

/* eslint-enable regex/invalid-error */
