import * as React from 'react'
/**
 * useAutoUpdatingRef is a ref hook that will automatically stay in sync with whatever value is passed in on each render
 * so you don't need to define it one, and then put ref.current = value on the next line to keep them in sync
 */
export function useAutoUpdatingRef<T>(input: T) {
  const ref = React.useRef<T>(input)
  ref.current = input
  return ref
}
