import styled from '@emotion/styled'
import { notebookUpMq } from '../../../styles/mediaQueries'
import { theme } from '../../../styles/theme'

export const modalCloseXClassName = 'modal-close-x'

export const StandardModalCard = styled.div<{
  $hideCloseX?: boolean
}>`
  position: relative;
  background: ${theme.colors.grayPorcelain};
  padding: 16px 20px 25px;
  border-radius: 8px;
  min-width: 260px;
  width: 400px;
  max-width: 90vw;
  min-height: 325px;
  height: fit-content;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 2.81794px 2.81794px 14.08971px 0px rgba(111, 140, 176, 0.41);

  .${modalCloseXClassName} {
    display: ${(p) => (p.$hideCloseX ? 'none' : 'initial')};
  }

  ${notebookUpMq} {
    padding: 16px 40px;
    min-height: 50vh;
  }
`
