import * as otelApi from '@opentelemetry/api';
import { rmDebug } from '../../common/tools/debug';
import { TelemetryKeys } from '../../features/tracing/constants';
import { setBaggageEntries } from '../../otel/instrumentation.client';

// eslint-disable-next-line no-restricted-imports
import { DANGEROUSLY_ACCESS_AUTH0_CONTEXT_OUTSIDE_TREE } from '../../features/Auth0ExternalUsageModuleSetter';

const debug = rmDebug.extend('fetch')

const operationNameRe = /"operationName"\s*:\s*"(?<op>[^"]+)"/m
const getOperationName = (input: string) => operationNameRe.exec(input)?.groups?.op

const operationTypeRe = /"query"\s*:\s*"(?<type>query|mutation|subscription)/m
const getOperationType = (input: string) => operationTypeRe.exec(input)?.groups?.type

const fetchWithTrace: typeof fetch = async (input, init) => {
  const baggageData: Partial<Record<TelemetryKeys, string>> = {}

  const { user } = DANGEROUSLY_ACCESS_AUTH0_CONTEXT_OUTSIDE_TREE ?? {}
  if (user?.sub) {
    baggageData[TelemetryKeys.userId] = user.sub
  }

  const body = init?.body?.toString() ?? ''
  const operationName = getOperationName(body)
  if (operationName) {
    baggageData[TelemetryKeys.graphqlOperationName] = operationName
  }

  const operationType = getOperationType(body)
  if (operationType) {
    baggageData[TelemetryKeys.graphqlOperationType] = operationType
  }

  return otelApi.context.with(setBaggageEntries(baggageData), async () => {
    const consoleKey = `${operationType} ${operationName}`;
    debug(consoleKey)
    debug(consoleKey, 'Running fetch')
    const res = await fetch(input, init)
    debug(consoleKey);

    return res
  })
}


export let fetchClient = fetchWithTrace;
