import styled from '@emotion/styled'
import { privacyPolicyRoute, termsOfUseRoute } from '../../../common/constants/routes'
import { NextLink } from '../../../common/functionality/NextLink'
import { hoverOpacity } from '../../../common/style/HoverOpacityWrapper'
import { theme } from '../../../styles/theme'
import { tabletDownMq } from '../../../styles/mediaQueries'

const NavList = styled.ul`
  width: 100%;
  text-align: center;
  padding: 10px 10px;
  color: ${theme.colors.grayMidnight};
  background: ${theme.colors.grayLightLightGray};
`

const NavListItem = styled.li`
  display: inline;
  :first-of-type {
    margin-right: 40px;
    ${tabletDownMq} {
      margin-right: 30px;
    }
  }
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.04em;

  ${hoverOpacity}
`

export const Footer = () => {
  return (
    <NavList>
      <NavListItem>
        <NextLink href={privacyPolicyRoute}>Privacy Policy</NextLink>
      </NavListItem>
      <NavListItem>
        <NextLink href={termsOfUseRoute}>Terms of Use</NextLink>
      </NavListItem>
    </NavList>
  )
}
