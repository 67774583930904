import dynamic from 'next/dynamic'
import { useHasOccurred } from '../../../hooks/useHasOccurred'
import { useAccount } from '../../auth/hooks/useAccount'

const RouteChangeProgressBar = dynamic<{}>(() =>
  import('./RouteChangeProgressBar').then((x) => x.RouteChangeProgressBar)
)

export function LazyRouteChangeProgressBar() {
  const { isAuthenticated } = useAccount()

  // mount the progress bar when user authenticates. prior to this, navigation
  // isn't possible anyway
  const progressbarShouldBeMounted = useHasOccurred(isAuthenticated)
  return progressbarShouldBeMounted ? <RouteChangeProgressBar /> : null
}
