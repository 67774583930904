import * as otelApi from '@opentelemetry/api'
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { CompositePropagator, W3CBaggagePropagator } from '@opentelemetry/core'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { B3InjectEncoding, B3Propagator } from '@opentelemetry/propagator-b3'
import { Resource } from '@opentelemetry/resources'
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'

export const initInstrumentation = (apiKey: string) => {
  const propagator = new CompositePropagator({
    propagators: [
      new W3CBaggagePropagator(),
      new B3Propagator({
        injectEncoding: B3InjectEncoding.MULTI_HEADER,
      }),
    ],
  })
  otelApi.propagation.setGlobalPropagator(propagator)

  const resource = new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: 'webapp-client',
  })

  const provider = new WebTracerProvider({ resource })

  otelApi.trace.setGlobalTracerProvider(provider)

  const contextManager = new ZoneContextManager().enable()
  otelApi.context.setGlobalContextManager(contextManager)
  const exporter = new OTLPTraceExporter({
    url: 'https://api.honeycomb.io/v1/traces',
    headers: {
      'x-honeycomb-team': apiKey,
    },
  })
  provider.addSpanProcessor(new BatchSpanProcessor(exporter))

  // Initialize the provider
  provider.register({
    propagator,
    contextManager,
  })

  // Registering instrumentations / plugins
  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-user-interaction': {
          enabled: false,
        },
        '@opentelemetry/instrumentation-xml-http-request': {
          enabled: false,
        },

        '@opentelemetry/instrumentation-fetch': {
          propagateTraceHeaderCorsUrls: [
            /host.docker\.internal/gi,
            /localhost/gi,
            /realize-dev\.hasura\.app/gi,
            /[\w]+\.realizeme\.io/gi,
            /[\w]+\.realize\.me/gi,
          ],
          ignoreNetworkEvents: true,
          clearTimingResources: true,
          ignoreUrls: [/stream-io/, /honeycomb/],
          applyCustomAttributesOnSpan: (span, _request, _result) => {
            const baggage = getBaggage()
            span.setAttributes(Object.fromEntries(baggage.getAllEntries().map(([key, { value }]) => [key, value])))
          },
        },
      }),
    ],
  })
}

export function getBaggage() {
  return otelApi.propagation.getBaggage(otelApi.context.active()) || otelApi.propagation.createBaggage()
}

export function setBaggageEntries(entries: Record<string, string>) {
  let baggage = getBaggage()
  for (const key in entries) {
    const value = entries[key]

    // !! FIX TS-IGNORE  IF RETURNING
    // @ts-ignore MASS  --noUncheckedIndexedAccess CHANGE. FIX IF RETURNING TO CURRENT BLOCK
    // !! FIX TS-IGNORE  IF RETURNING
    baggage = baggage.setEntry(key, { value })
  }
  return otelApi.propagation.setBaggage(otelApi.context.active(), baggage)
}
