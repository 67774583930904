import { useRouter } from 'next/router'
import CartSvg from '../../../assets/icons/cart.svg'
import CartWithDotSvg from '../../../assets/icons/cart-with-dot.svg'
import { shopCartRoute } from '../../../common/constants/routes'
import { NextLink } from '../../../common/functionality/NextLink'
import styled from '@emotion/styled'
import { hamburgerContainerStyles } from '../../side-navigation/atoms/Hamburger'
import { transientOptions } from '../../../common/style/transientOptions'
import { useShoppingCart } from '../../shop/hooks/useShoppingCart/useShoppingCart'

const Container = styled(NextLink, transientOptions)<{ $isActive: boolean }>`
  transition:
    scale 0.3s,
    opacity 0.3s;

  ${hamburgerContainerStyles}
`

export function CartNavItem() {
  const { pathname } = useRouter()

  const { itemCount } = useShoppingCart().cart

  return (
    <Container href={shopCartRoute} $isActive={pathname === shopCartRoute}>
      {itemCount ? <CartWithDotSvg /> : <CartSvg />}
    </Container>
  )
}
