import styled from '@emotion/styled'
import { transientOptions } from './transientOptions'

export const AdjustableWeightHiddenPsuedoText = styled('span', transientOptions)<{
  $text?: string
  $largestWeight?: number
}>`
  position: relative;
  display: inline-block;
  transition: font-weight 0.3s;

  &::before {
    content: ${(p) => p.$text ?? ''};
    font-weight: ${(p) => p.$largestWeight ?? 'unset'}; // The largest weight the text will be
    visibility: hidden;
    position: absolute;
  }
`
