import React from 'react'
import styled from '@emotion/styled'
import { Overlay } from '../../../common/style/Overlay'
import { useRecoilState } from 'recoil'
import { sidebarOpenState } from '../recoil'
import { useRouter } from 'next/router'
import { CrossDerivative } from '../../../assets/components/CrossDerivative'
import { theme } from '../../../styles/theme'
import { isActiveUrl, Subnav } from '../atoms/Subnav'
import { tabletDownMq } from '../../../styles/mediaQueries'
import { useNavLinks } from '../hooks/useNavLinks'
import { SidebarLink } from '../atoms/SidebarLink'
import { UnstyledButton } from '../../../common/style/UnstyledButton'
import { hoverOpacity } from '../../../common/style/HoverOpacityWrapper'
import { useLogOut } from '../../auth/hooks/useLogOut'

const Container = styled.aside<{ isOpen: boolean }>`
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 375px;
  padding: 48px 0;
  transform: translateX(${(p) => (p.isOpen ? 0 : 100)}%);
  transition: 0.3s ease-in-out;
  font-size: 20px;
  line-height: 18px;
  background: ${theme.colors.grayPorcelain};
  display: flex;
  flex-direction: column;
  color: rgba(35, 98, 134, 0.5);
  align-items: flex-start;
  cursor: default;
  min-width: 350px;
  max-width: 90vw;
  font-weight: 300;

  a {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  ${tabletDownMq} {
    width: 85vw;
    min-width: 300px;
    padding-top: calc(36px + env(safe-area-inset-top));
    padding-bottom: calc(36px + env(safe-area-inset-bottom));
  }
`

const CloseXButton = styled(CrossDerivative)`
  position: absolute;
  padding: 14px;
  top: env(safe-area-inset-top);
  right: 0;
  transform: translateX(-10%);
`

const LogoutButton = styled(UnstyledButton)`
  padding: 16px 24px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: ${theme.colors.aegean};

  ${hoverOpacity}

  ${tabletDownMq} {
    font-size: 20px;
    padding-left: 22px;
  }
`

export function Sidebar() {
  const [isOpen, setIsOpen] = useRecoilState(sidebarOpenState)
  const router = useRouter()

  React.useEffect(() => {
    const onRouteChangeStart = () => setIsOpen(false)
    router.events.on('routeChangeStart', onRouteChangeStart)
    return () => router.events.off('routeChangeStart', onRouteChangeStart)
  }, [router.events, setIsOpen])

  const { pathname } = router

  const { navLinks, subnav } = useNavLinks()

  const logout = useLogOut()

  return (
    <Overlay onClick={() => setIsOpen(false)} isVisible={isOpen}>
      <Container isOpen={isOpen}>
        <CloseXButton color={theme.colors.aegean} onClick={() => setIsOpen((s) => !s)} aria-label="close" />
        {navLinks
          .filter((x) => (!x.hideLinkOnNavs || x.TEMP_ignoreHideOnNavLinksWhenShowingOnSidebar) && !x.hideLinkOnSidebar)
          .map((x) =>
            'subnav' in x ? (
              <Subnav {...x} key={x.title} subnavIsActive={x.subnav === subnav} />
            ) : (
              <SidebarLink href={x.url} id={x.id} key={x.url} isActive={isActiveUrl({ url: x.url, pathname })}>
                {x.title}
              </SidebarLink>
            )
          )}
        <LogoutButton onClick={() => logout()}>Log out</LogoutButton>
      </Container>
    </Overlay>
  )
}
