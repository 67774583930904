import React from 'react'
import { isElement } from '../typescript/guards/isElement'

type Element<T extends HTMLElement = HTMLElement> = T | null

// TODO the ref input type here does not seem to work without casting null|HTMLELement to a ref HTML element.
// TODO Also don't think it accepts specific element types which it should
export type UseElementInput<T extends HTMLElement = HTMLElement> =
  | string
  | Element<T>
  | React.MutableRefObject<Element<T>>
  | null

export function getElementFromUseElementInput<T extends HTMLElement = HTMLElement>(
  input?: UseElementInput
): Element<T> {
  if (typeof window === 'undefined') return null
  if (typeof input === 'string') {
    const elt: Element<T> | null = document.querySelector(input)
    return elt
  }
  if (input && typeof input === 'object' && 'current' in input) {
    return input.current as Element<T>
  }
  if (input instanceof HTMLElement) {
    return input as Element<T>
  }
  return null
}

/**
 * @param {UseElementInput} querySelector - a dom element, a react ref pointing to a dom element, or a query selector string
 * @returns the matching dom element, or null if no match
 */
export function useElement<T extends HTMLElement = HTMLElement>(input: UseElementInput): Element<T> {
  const [element, setElement] = React.useState<Element>(() => getElementFromUseElementInput<T>(input))

  // if we are using a ref, we need to pay attention to whther the ref's value changes and re-run the effect
  const current = !!input && typeof input === 'object' && 'current' in input ? input.current : null

  React.useEffect(() => {
    const elt = getElementFromUseElementInput(input)
    setElement(elt)
  }, [input, current])

  return isElement<T>(element) ? element : null
}
