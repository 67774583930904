import { throttle } from 'lodash'
import { isPwa } from '../utilities/isPwa'
import { isPortraitMq } from './mediaQueries'

export const visibleScreenHeightVarName = '--visible-screen-height'
export const visibleScreenHeightVar = `var(${visibleScreenHeightVarName})`

export const fixiOS100vhIssue = () => {
  if (typeof window !== 'undefined') {
    const portrait = window.matchMedia(isPortraitMq)
    const setVisibleScreenHeight = throttle(() => {
      document.documentElement.style.setProperty(
        visibleScreenHeightVarName,
        // i think safe-area-inset-top is 0 outside PWA anyway, but this is more clear why it is here anyway if you use
        // the ternary
        isPwa ? `calc(${window.innerHeight}px + env(safe-area-inset-top))` : `${window.innerHeight}px`
      )
    }, 50)

    portrait.addEventListener('change', setVisibleScreenHeight)
    window.addEventListener('resize', setVisibleScreenHeight)
    setVisibleScreenHeight()
    // on pwa, screen size seems to change, but resize is not being fired i don't think. just force a calculation every
    // 1000

    // !!update: the screen orientation listener above may have rendered this unnecessary. need to test though
    if (isPwa) setInterval(setVisibleScreenHeight, 1000)
  }
}
