import jsCookie from 'js-cookie' // eslint-disable-line no-restricted-imports
import { Falsy } from 'utility-types'

export const sessionWasAuthedCookieName = 'sessionWasAuthed'
export const tokenCookieName = 'token'
export const pointingToProdNotificationSeenCookieName = 'pointingToProdNotificationSeen'
export const isUpgradeLabsUserCookieName = 'isUpgradeLabsUser' // utils

export const isInLogInProcessCookieName = 'isInLogInProcess'
export const userHasGoalsCookieName = 'userHasGoals'
export const userIsPaidMemberCookieName = 'userIsPaidMember'

export const setTokenCookie = (token?: string | Falsy) => {
  if (token) {
    const isLocal =
      (typeof window !== 'undefined' && location.hostname === 'localhost') || process.env.NODE_ENV !== 'production'

    jsCookie.set(tokenCookieName, token, { ...(!isLocal && { httpOnly: true }), secure: true, sameSite: 'strict' })
  } else {
    jsCookie.remove(tokenCookieName)
  }
}
