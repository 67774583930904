import * as React from 'react'
import styled from '@emotion/styled'
import Router from 'next/router'
import { UnstyledButton } from '../../common/style/UnstyledButton'
import { hoverOpacity } from '../../common/style/HoverOpacityWrapper'
import * as Sentry from '@sentry/nextjs'
import { LEGACY_NextImage } from '../../common/functionality/LEGACY_NextImage'
import { homeRoute } from '../../common/constants/routes'
import { realizeLogoCeruleanCircleSrc } from '../../common/constants/s3ImageUrls'
import { theme } from '../../styles/theme'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 20vw;
`

const Text = styled.div`
  margin-top: 40px;
`

const Cta = styled(UnstyledButton)`
  font-size: 22px;

  ${hoverOpacity}
`

const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`

const Divider = styled.div`
  height: 24px;
  width: 2px;
  background: ${theme.colors.grayCoal};
  margin: 0 15px;
`

export function ErrorBoundary({ children }: React.PropsWithChildren<{}>) {
  return (
    <Sentry.ErrorBoundary
      fallback={function Fallback({ resetError }) {
        return (
          <Container>
            <LEGACY_NextImage src={realizeLogoCeruleanCircleSrc} alt="realize-me-logo" width={100} height={100} />
            <>
              <Text>We encountered an error.</Text>
              <CtaContainer>
                <Cta
                  onClick={() => {
                    resetError()
                    Router.push(homeRoute)
                  }}
                >
                  Home
                </Cta>
                <Divider />
                <Cta
                  onClick={() => {
                    resetError()
                    Router.back()
                  }}
                >
                  Back
                </Cta>
              </CtaContainer>
            </>
          </Container>
        )
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
