import getConfig from 'next/config'
import * as React from 'react'

export const getPublicEnvs = (): State => getConfig()?.publicRuntimeConfig.publicEnvs ?? {}

type State = Partial<{
  SENTRY_DSN: string
  AUTH0_AUDIENCE: string
  AUTH0_DOMAIN: string
  AUTH0_CLIENT_ID: string
  NEST_API_ENDPOINT: string
  AIRPLANE_MODE: boolean
  OPTIMIZELY_SDK_KEY: string
  STRIPE_PUBLISHABLE_KEY: string
  ENVIRONMENT: string
  GIT_SHA: string
  GIT_TAG: string
  GIT_COMMIT_MESSAGE: string
  HONEYCOMB_API_KEY: string
  FACEBOOK_CONVERSIONS_PIXEL_ID: string
  HASURA_ENDPOINT: string
  STELLATE_CACHEABLE_GQL_HTTP_ENDPOINT: string
  ORIGIN_SERVER_URL: string
}>

export function usePublicEnv() {
  const [publicEnvs] = React.useState<State>(getPublicEnvs)
  return publicEnvs ?? {}
}
