import { Role } from './roles'

// CLIENT AND SERVER
export const xHasuraUserIdHeader = 'x-hasura-user-id'
export const xHasuraRoleHeader = 'x-hasura-role'
export const xBypassCacheHeader = 'x-bypass-cache'
// SERVER ONLY
export const xHasuraAdminSecretHeader = 'x-hasura-admin-secret'
export const xHasuraAssumedByHeader = 'x-hasura-assumed-by'

export const bypassCacheContext = { context: { headers: { [xBypassCacheHeader]: 'true' } } }
export const adminRoleHeaderContext = { context: { headers: { [xHasuraRoleHeader]: Role.admin } } }
