import styled from '@emotion/styled'
import { AdjustableWeightHiddenPsuedoText } from '../../../common/style/AdjustableWeightHiddenPsuedoText'
import { transientOptions } from '../../../common/style/transientOptions'
import { theme } from '../../../styles/theme'

const ItemContainer = styled(AdjustableWeightHiddenPsuedoText, transientOptions)<{ $isActive: boolean }>`
  color: ${(p) => (p.$isActive ? theme.colors.grayPorcelain : theme.colors.cloud)};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: ${(p) => (p.$isActive ? 800 : 700)};
  letter-spacing: 0.48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover:not(:disabled) {
    color: ${theme.colors.grayPorcelain};
  }
`

export const LeftSideItemContainer = styled(ItemContainer)`
  padding: 8px 9px;
  margin-left: 4px;
`
export const RightSideItemContainer = styled(ItemContainer)`
  width: 40px;
`
