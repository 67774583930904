import { merge } from 'lodash'
import { isQueryOperation } from '@apollo/client/utilities'
import { DocumentNode } from 'graphql'

/**
 * Automatically set all query/lazyquery options' fetchPolicy to cache and network unless the options already contain a
 * fetch policy. this is necessary everywhere since
 * we save the cache in session storage and refresh apollo from storage on refresh. if we don't use cache-and-network,
 * the request gets saved to session storage, and on refresh the cache is hydrated with the stored value, and the
 * request never runs again to get the updated data.
 */
export function defaultQueriesToCacheAndNetwork<T>(options: T, documentNode: DocumentNode) {
  if (!isQueryOperation(documentNode)) return options
  const hasFetchPolicy = options && typeof options === 'object' && 'fetchPolicy' in options
  return hasFetchPolicy ? options : merge({ fetchPolicy: 'cache-and-network' }, options)
}
