import * as React from 'react'
import { useRecoilState } from 'recoil'
import { devToolsState } from '../features/admin/dev-tools/recoil'
import { airplaneModeSessionStorageKey } from '../common/constants/sessionStorage'

export function useDevTools() {
  const [devTools, setDevTools] = useRecoilState(devToolsState)

  React.useEffect(() => {
    sessionStorage.setItem(airplaneModeSessionStorageKey, String(devTools.airplaneMode))
  }, [devTools.airplaneMode])
  return [devTools, setDevTools] as const
}
