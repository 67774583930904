import { ApolloCache, ApolloClient, from, NormalizedCacheObject, InMemoryCache } from '@apollo/client'
import { inMemoryCacheOptions } from './inMemoryCacheOptions'
import { authLink } from './links/authLink'
import { makeConnectionLink } from './links/connectionLink'
import { networkStatusLink } from './links/networkStatusLink'

export type CreateApolloOptions = {
  initialState?: NormalizedCacheObject
  cache?: ApolloCache<NormalizedCacheObject>
  airplaneMode?: boolean
  wsUrl?: string
}

export type CreateApollo = typeof createApollo

export type CreateApolloReturn = ReturnType<CreateApollo>

export function createApollo({
  initialState,
  cache = new InMemoryCache(inMemoryCacheOptions),
  airplaneMode,
  wsUrl,
}: CreateApolloOptions = {}) {
  const restoredCache = cache.restore(initialState ?? {})

  return new ApolloClient({
    link: from([networkStatusLink, authLink, makeConnectionLink(wsUrl)]),
    ssrMode: typeof window === 'undefined',
    cache: restoredCache,
    ...(airplaneMode && {
      defaultOptions: {
        query: {
          fetchPolicy: 'cache-only',
        },
      },
    }),
  })
}
