import * as React from 'react'
import { useRouter } from 'next/router'
import { UnstyledButton } from '../../../common/style/UnstyledButton'
import styled from '@emotion/styled'
import { LEGACY_Chevron } from '../../../assets/components/LEGACY_Chevron'
import { Collapse } from '../../../common/style/Collapse'
import { theme } from '../../../styles/theme'
import { LinkWithoutSubnav } from '../hooks/useNavLinks'
import { SidebarLink } from './SidebarLink'
import { hoverOpacity } from '../../../common/style/HoverOpacityWrapper'
import { useAutoUpdatingRef } from '../../../hooks/useAutoUpdatingRef'
import { isBoolean } from 'lodash'

const SubnavTitle = styled(UnstyledButton)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  color: ${theme.colors.aegean};

  ${hoverOpacity}
`

type IsActiveUrlInput = { url: string; pathname: string }

export const isActiveUrl = ({ url, pathname }: IsActiveUrlInput) => url.split('?').shift() === pathname

type Props = {
  title: string
  subnav: LinkWithoutSubnav[]
  id?: string
  subnavIsActive: boolean
  isOpenByDefault?: boolean
}

export function Subnav({ title, subnav, id, subnavIsActive, isOpenByDefault }: Props) {
  const [lastDefaultedIsOpenState, setLastDefaultedIsOpenState] = React.useState(false)

  const defaultedStateUnsetRef = useAutoUpdatingRef(!!lastDefaultedIsOpenState !== !!isOpenByDefault)

  const { pathname } = useRouter()
  const [isOpen, setIsOpen] = React.useState(subnavIsActive)

  React.useEffect(() => {
    if (isBoolean(isOpenByDefault)) {
      setIsOpen(isOpenByDefault)
      setLastDefaultedIsOpenState(isOpenByDefault)
    }
  }, [isOpenByDefault])

  React.useEffect(() => {
    if (!defaultedStateUnsetRef.current) {
      setIsOpen(subnavIsActive)
    }
  }, [subnavIsActive, pathname, defaultedStateUnsetRef])

  // ?? LinkWithoutSubNav has a property called 'hideLinkOnNavs' that is useful to keep the Subnav presence
  //    and the QuickLink active behaviors even if we don't want a particular route to render on the Subnav
  //    and/or the Sidebar.
  //    If a route Link does not have to be rendered here, check if it has to be hidden on the Navbar as well.
  const filteredSubnav = subnav.filter(
    (x) => !x.hideLinkOnNavs && (!x.hideLinkOnSidebar || x.TEMP_ignoreHideOnNavLinksWhenShowingOnSidebar)
  )

  return (
    <>
      <SubnavTitle onClick={() => setIsOpen((s) => !s)} id={id}>
        {title}
        <LEGACY_Chevron
          color={theme.colors.aegean}
          style={{ margin: '7px 5px 0' }}
          orientation={isOpen ? 'up' : 'down'}
        />
      </SubnavTitle>
      <Collapse isOpen={isOpen} style={{ width: '100%' }}>
        {filteredSubnav.map((x) => (
          <SidebarLink
            href={x.url}
            key={x.url}
            id={x.id}
            target={x.target}
            isActive={subnavIsActive && isActiveUrl({ url: x.url, pathname })}
            nested
          >
            {x.title}
          </SidebarLink>
        ))}
      </Collapse>
    </>
  )
}
