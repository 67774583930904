import isNil from 'lodash/isNil'
import * as React from 'react'

type Delay = number | null
type TimerHandler = (...args: any[]) => void

/**
 * Provides a declarative useInterval
 *
 * @param callback - Function that will be called every `delay` ms.
 * @param delay - Number representing the delay in ms. Set to `null` to "pause" the interval.
 */

export const useInterval = (callback: TimerHandler, delay: Delay) => {
  const savedCallbackRef = React.useRef<TimerHandler>()

  React.useEffect(() => {
    savedCallbackRef.current = callback
  }, [callback])

  React.useEffect(() => {
    const handler = (...args: any[]) => savedCallbackRef.current?.(...args)

    if (!isNil(delay)) {
      const intervalId = setInterval(handler, delay)
      return () => clearInterval(intervalId)
    }
  }, [delay])
}
