import * as React from 'react'
import { useRouter } from 'next/router'
import { previousUrlAndScrollPositionSessionStorageKey } from '../common/constants/sessionStorage'

export type PreviousUrlAndScrollPosition = { url: string; x: number; y: number }

export function isPreviousUrlAndScrollPosition(input: any): input is PreviousUrlAndScrollPosition {
  return (
    !!input &&
    typeof input === 'object' &&
    'x' in input &&
    Number.isFinite(input.x) &&
    'y' in input &&
    Number.isFinite(input.y) &&
    'url' in input &&
    typeof input.url === 'string'
  )
}

export const getPreviousUrl = () => {
  const prev = getPreviousUrlAndScrollPosition()
  return prev?.url ?? null
}

const getUrlScrollObjectFromUrl = (url: string) => ({ url, x: window.scrollX, y: window.scrollY })

export function getPreviousUrlAndScrollPosition() {
  if (typeof window === 'undefined') return null
  const saved = JSON.parse(sessionStorage.getItem(previousUrlAndScrollPositionSessionStorageKey) ?? '{}')
  return isPreviousUrlAndScrollPosition(saved) ? saved : null
}

/**
 * NextJS' experimental scroll restoration works fine in cases where the user is navigating via the forward or back
 * buttons in the browser.However, there are cases where we want to navigate to the previous page the user was on within
 * our app, but, due to limitations in the next implementation of history/navigation, it is impossible to construct the
 * history entry stack for the current domain (next doesn't differentiate between forward/back popStates) and know the
 * index the user is currently at in the stack. Thus, if we actually programmatically use 'back' from history or next router, we may end
 * up pushing the user off our domain onto a previous site, which we never want (if they click the 'back' button, they
 * can still navigate off our site, which is good). Thus, we never want to use 'back', and instead we create a function
 * that always pushes a new entry onto the stack, but just goes to whatever their previous route was (see 'useGoBack').
 * thus, we use this to always save the previous route and scroll position on all navigations. we then use this if we
 * want to navigate back within 'useGoBack'. to restore the scroll position on non forward/back browser events (which
 * nextJS already handles), in navigations which are not popState navigations (things like link clicks and programmatic
 * navigation. basically anything but back/forward buttons, as well as next's 'back'/'forward' functions(which we don't
 * use in this app for the reasons stated above)), we check if the previous url is the same as where we just landed, and
 * if so, we scroll to the position we saved when the user left previously.
 */
export function useSavePreviousPageAndScrollPositionAndRestoreScrollBeyondNextExperimental() {
  const router = useRouter()

  const scrollPositionPriorToNavigationStartRef = React.useRef<null | PreviousUrlAndScrollPosition>(null)

  const isPopStateNavigationRef = React.useRef(false)

  React.useEffect(() => {
    // whenever a route change begins (pop state or not), save the route/scroll position to local var so as to overwrite
    // previous sesstion storage value once route change complete/previous url and position have been read by route
    // change complete function

    const onRouteChangeStart = () => {
      scrollPositionPriorToNavigationStartRef.current = getUrlScrollObjectFromUrl(router.asPath)
    }
    // if we are not in a popstate navigation (forward/back button navigation, which next js experimental scroll
    // restoration will handle), check to see if the current url is the same as whatever the previous one was (such as if
    // i clicked from home to profile, and then clicked a link to home from profile. this is not a popstate navigation
    // since we are initiating it/it isn't a forward/back button navigation, but so long as the new url is the same as
    // the one we were on before the page we are navigating away from, we want to restore scroll to that page)
    const onRouteChangeComplete = (url: string) => {
      const from = scrollPositionPriorToNavigationStartRef.current
      const saved = getPreviousUrlAndScrollPosition()

      if (!isPopStateNavigationRef.current && saved && url === saved.url) {
        window.scrollTo(saved.x, saved.y)
      }

      sessionStorage.setItem(previousUrlAndScrollPositionSessionStorageKey, JSON.stringify(from))
      isPopStateNavigationRef.current = false
    }

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    router.beforePopState(() => {
      isPopStateNavigationRef.current = true
      return true
    })
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
      router.beforePopState(() => true)
    }
  }, [router])
}
