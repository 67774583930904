import * as React from 'react'
import styled from '@emotion/styled'
import { HomeButton } from '../atoms/HomeButton'
import { Hamburger } from '../../side-navigation/atoms/Hamburger'
import { QuickLinks } from '../molecules/QuickLinks'
import { LazyLoadingSpinner } from '../../../common/components/LazyLoadingSpinner'
import { useNavLinks } from '../../side-navigation/hooks/useNavLinks'
import { tabletDownMq } from '../../../styles/mediaQueries'
import { useLogInState } from '../../auth/recoil'
import { useAnnouncementBanner_LEGACY } from '../hooks/useAnnouncementBanner_LEGACY'
import { useRouter } from 'next/router'
import { useSetAnnouncementBanner } from '../hooks/useAnnouncementBanner'
import { transientOptions } from '../../../common/style/transientOptions'
import { useHideHeaderOnScroll } from '../hooks/useHideHeaderOnScroll'
import { pathsWithoutHeader, patternsWithoutHeader } from '../pathsWithoutHeader'
import { UnstyledButton } from '../../../common/style/UnstyledButton'
import { theme } from '../../../styles/theme'
import { useAccount } from '../../auth/hooks/useAccount'
import dynamic from 'next/dynamic'
import { useLoadDevToolsNavIndicators } from '../../admin/dev-tools/hooks/useLoadDevToolsNavIndicators'
import { useDevTools } from '../../../hooks/useDevTools'
import { useIsUpgradeLabsUser } from '../../upgrade-labs/hooks/useIsUpgradeLabsUser'
import { CartNavItem } from '../atoms/CartNavItem'
import { Display } from '../../../common/style/Display'

const LazyDevToolsNavIndicators = dynamic(() =>
  import('../../admin/dev-tools/organisms/DevToolsNavIndicators').then((x) => x.DevToolsNavIndicators)
)

const LazySubnav = dynamic(() => import('../molecules/Subnav').then((x) => x.Subnav))

const Container = styled('nav', transientOptions)<{ $hideHeader: boolean; $isUpgradeLabsUser: boolean }>`
  transform: translateY(${(p) => (p.$hideHeader ? -100 : 0)}%);
  transition: transform 0.3s ease-out;

  pointer-events: auto;
  --nav-side-padding: ${(p) => (p.$isUpgradeLabsUser ? 16 : 64)}px;

  ${tabletDownMq} {
    --nav-side-padding: 8px;
  }
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const MainNavContent = styled('div', transientOptions)`
  color: ${theme.colors.grayPorcelain};
  display: flex;
  position: relative;
  z-index: 1;
  background-color: ${theme.colors.aegean};
  justify-content: space-between;
  align-items: center;
  padding: env(safe-area-inset-top) var(--nav-side-padding) 0;
  height: calc(48px + env(safe-area-inset-top));
  display: flex;
  justify-content: center;
`

const RightSideIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogInSignUpButtons = styled(UnstyledButton)`
  text-align: center;
  font-family: 'HK Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  color: ${theme.colors.cloud};
  height: 48px;

  :hover {
    color: ${theme.colors.grayPorcelain};
  }

  ${tabletDownMq} {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.48px;
  }
`

const LogInSignUpDivider = styled(LogInSignUpButtons)`
  line-height: 48px;
  :hover {
    opacity: 1;
  }
`.withComponent('div')

const LogInSignUpWrapper = styled('div', transientOptions)`
  display: flex;
  align-items: center;
`

export const navbarId = 'navbar'

const LeftSide = styled.div`
  display: flex;
  align-items: center;
`

export function Navbar() {
  const AnnouncementBanner_LEGACY = useAnnouncementBanner_LEGACY()

  const {
    AnnouncementBanner,
    recoil: [{ hideHeaderOnScroll }],
  } = useSetAnnouncementBanner()

  const bannerJsx = AnnouncementBanner_LEGACY ? (
    <AnnouncementBanner_LEGACY />
  ) : AnnouncementBanner ? (
    <AnnouncementBanner />
  ) : null

  const { pathname } = useRouter()

  const { hideHeader } = useHideHeaderOnScroll()

  const { isAuthenticated, isLoading } = useAccount()
  const { subnav } = useNavLinks()

  const [, setLogInState] = useLogInState()

  const [{ airplaneMode }] = useDevTools()
  const showFullNav = isAuthenticated || airplaneMode

  const loadDevToolsNavIndicators = useLoadDevToolsNavIndicators()
  const { isUpgradeLabsUser } = useIsUpgradeLabsUser()

  if (pathsWithoutHeader.has(pathname) || patternsWithoutHeader.some((x) => x.test(pathname))) {
    return null
  }

  return (
    <Container id={navbarId} $hideHeader={!!hideHeaderOnScroll && hideHeader} $isUpgradeLabsUser={isUpgradeLabsUser}>
      {loadDevToolsNavIndicators && <LazyDevToolsNavIndicators />}
      <MainNavContent>
        <ContentContainer>
          <LeftSide>
            <HomeButton />
            <QuickLinks />
          </LeftSide>
          {isLoading ? (
            <LazyLoadingSpinner isSmall />
          ) : (
            <RightSideIcons>
              <CartNavItem />
              {showFullNav ? (
                <>
                  <Hamburger />
                </>
              ) : (
                <>
                  <LogInSignUpWrapper>
                    <LogInSignUpButtons
                      style={{ paddingRight: 3, paddingLeft: 8 }}
                      onClick={() =>
                        setLogInState((s) => ({
                          ...s,
                          isLoggingIn: true,
                        }))
                      }
                    >
                      log In
                    </LogInSignUpButtons>
                    <Display isTabletUp>
                      <div style={{ display: 'flex' }}>
                        <LogInSignUpDivider>/</LogInSignUpDivider>
                        <LogInSignUpButtons
                          style={{ paddingLeft: 3 }}
                          onClick={() =>
                            setLogInState((s) => ({
                              ...s,
                              isLoggingIn: true,
                              loginOptions: { screen_hint: 'signup' },
                            }))
                          }
                        >
                          sign up
                        </LogInSignUpButtons>
                      </div>
                    </Display>
                  </LogInSignUpWrapper>
                </>
              )}
            </RightSideIcons>
          )}
        </ContentContainer>
      </MainNavContent>

      {subnav.length > 1 && <LazySubnav subnav={subnav} />}
      {bannerJsx}
    </Container>
  )
}
