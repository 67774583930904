import styled from '@emotion/styled'
import * as React from 'react'
import Link, { LinkProps } from 'next/link'
import { UnstyledLink } from '../style/UnstyledLink'
import { ClickEvent } from '../../typescript/utility'
import { theme } from '../../styles/theme'
import { Styles, getCssProp } from '../../utilities/getCssProp'

export const newRealizeLinkStyles = `    color: ${theme.colors.cerulean};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-decoration: underline;`

export type NextLinkProps = React.PropsWithChildren<
  LinkProps & {
    style?: React.CSSProperties
    styles?: Styles
    onClick?: (e: ClickEvent<HTMLAnchorElement>) => void
    className?: string
    target?: React.HTMLAttributeAnchorTarget
  } & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>
>

function NextLinkBase(
  {
    children,
    style = {},
    onClick = () => {},
    className,
    target,
    ['aria-label']: ariaLabel,
    styles,
    id,
    ...rest
  }: NextLinkProps,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const cssProp = getCssProp(styles)
  return (
    <Link {...rest} passHref legacyBehavior>
      <UnstyledLink
        css={cssProp}
        id={id}
        style={style}
        ref={ref}
        onClick={onClick}
        className={className}
        target={target}
        aria-label={ariaLabel}
      >
        {children}
      </UnstyledLink>
    </Link>
  )
}

export const NextLink = React.forwardRef(NextLinkBase)

type DisabledProps = React.PropsWithChildren<{ disabled: true; styles?: Styles; children?: React.ReactNode }>

type RegularProps = NextLinkProps & { disabled?: false }

const DisabledWrapper = styled.span`
  cursor: not-allowed;
`

type AnchorRef = React.ForwardedRef<HTMLAnchorElement>
type SpanRef = React.ForwardedRef<HTMLSpanElement>

// !!Type inference failing when actually using the component. Says href is required in DisableableLinkProps which is
// patently false

export type DisableableLinkProps = DisabledProps | RegularProps
function DisableableLinkBase(disabledProps: DisabledProps, ref: SpanRef): JSX.Element
function DisableableLinkBase(regularProps: RegularProps, ref: AnchorRef): JSX.Element
function DisableableLinkBase(props: DisableableLinkProps, ref: AnchorRef | SpanRef) {
  const { disabled, ...rest } = props
  switch (disabled) {
    case true: {
      const cssProp = getCssProp(props.styles)
      return (
        <DisabledWrapper css={cssProp} ref={ref}>
          {props.children}
        </DisabledWrapper>
      )
    }
    default: {
      // can't seem to get the type inference working here
      return (
        <NextLink {...props} ref={ref as unknown as React.ForwardedRef<HTMLAnchorElement>}>
          {rest.children}
        </NextLink>
      )
    }
  }
}

export const DisableableLink = React.forwardRef(DisableableLinkBase)
