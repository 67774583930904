import { OperationVariables, QueryHookOptions } from '@apollo/client'
import { User as Auth0User } from '@auth0/auth0-react'
import { merge } from 'lodash'
import { xHasuraRoleHeader } from './../../common/constants/headers'
import { Role } from './../../common/constants/roles'

import { isRealizeAdminEmailRegex } from './common'
import {
  isNewUserFirstLoggedInPageLoadSessionStorageKey,
  isNewUserSessionStorageKey,
  loginInitiatedAsSignupSessionStorageKey,
  referralSourceSessionStorageKey,
} from '../../common/constants/sessionStorage'
import { enum_membership_tier_enum } from '../../generated/graphql'

interface HasuraClaims {
  'https://hasura.io/jwt/claims'?: {
    'x-hasura-allowed-roles': Role[]
    'x-hasura-default-role': Role
    'x-hasura-user-id': string
  }
}
interface UpgradeHealthClaims {
  'https://upgradehealth.com/jwt/claims'?: {
    email: string
    membershipTier: enum_membership_tier_enum
    roles: Role[]
    loginRequestId: string
  }
}

export type Auth0UserWithCustomClaims = Auth0User & ICustomClaims

interface ICustomClaims extends HasuraClaims, UpgradeHealthClaims {}

export class RealizeUser extends Auth0User {
  roles = [Role.anonymous]
  isAuthenticated = false
  defaultRole = Role.anonymous
  isRealizeAdmin = false
  membershipTier: enum_membership_tier_enum | null = null
  id = ''
  isNewUser = false
  loginInitiatedAsSignup = false
  referralSource: null | string = null
  isNewUserFirstLoggedInPageLoad = false

  hasRole = (role: Role) => {
    return this.roles.includes(role)
  }

  static create(user: Partial<RealizeUser>) {
    const newUser = new RealizeUser()
    return Object.assign(newUser, user)
  }
  static fromAuth0User(auth0User: Auth0UserWithCustomClaims): RealizeUser {
    const user = new RealizeUser()
    Object.assign(user, auth0User)

    const hasuraClaims = auth0User['https://hasura.io/jwt/claims']
    const upgradeHealthClaims = auth0User['https://upgradehealth.com/jwt/claims']

    user.roles = hasuraClaims?.['x-hasura-allowed-roles'] ?? [Role.user]
    user.defaultRole = hasuraClaims?.['x-hasura-default-role'] ?? Role.user
    user.isRealizeAdmin = isRealizeAdminEmailRegex.test(auth0User?.email || '')

    user.membershipTier = upgradeHealthClaims?.membershipTier || null
    user.id = auth0User.sub ?? ''
    user.isNewUser = sessionStorage.getItem(isNewUserSessionStorageKey) === user.id
    user.referralSource = sessionStorage.getItem(referralSourceSessionStorageKey) ?? null
    user.loginInitiatedAsSignup = sessionStorage.getItem(loginInitiatedAsSignupSessionStorageKey) === 'true'
    user.isNewUserFirstLoggedInPageLoad =
      sessionStorage.getItem(isNewUserFirstLoggedInPageLoadSessionStorageKey) === 'true'
    return user
  }
  getScopedQueryHookOptions<TData, TVariables extends OperationVariables>(
    role?: Role,
    options: QueryHookOptions<TData, TVariables> = {},
    skipRoleCheck = false
  ) {
    if (!role || role === Role.anonymous) {
      return options
    }

    return merge(
      {
        context: {
          headers: {
            [xHasuraRoleHeader]: role,
          },
        },
        skip: !skipRoleCheck && !this.hasRole(role),
      },
      options
    )
  }

  static init() {
    const user = new RealizeUser()
    return user
  }
}
