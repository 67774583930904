import type { AppContext, AppProps } from 'next/app'
import { DeviceProvider } from '../hooks/useDevice'
import { GoogleTagManager } from '@next/third-parties/google'
import { RecoilRoot } from 'recoil'
import { initReactAxe } from '../common/a11y/initReactAxe'
import { AuthProvider } from '../features/auth/providers/AuthProvider'
import { ErrorBoundary } from '../features/errors/ErrorBoundary'
import { Modal } from '../features/modal/organisms/Modal'
import { MonitoringAndSyncing } from '../features/monitoring-and-syncing/MonitoringAndSyncing'
import { Footer } from '../features/navbar/atoms/Footer'
import { Navbar } from '../features/navbar/organisms/Navbar'
import { AppLayoutTemplate } from '../features/navbar/templates/AppLayoutTemplate'
import { Sidebar } from '../features/side-navigation/molecules/Sidebar'
import { useRecoilizeDebugger } from '../hooks/useRecoilizeDebugger'
import { filterConsole } from '../utilities/filterConsole'

// TODO would like to implement this with the parser being qs so we can use nested objects. However, this library is
// using an older version of use-query-params which has either no way or a different/unknown way to pass in
// stringify/parser functions. either just update the library to use the new version (library is just a package of this
// thread's result https://github.com/pbeshai/use-query-params/issues/13) or figure out how to pass these functions in
// in the older version of use-query-params which next-query-params was built with

import { getDataFromTree } from '@apollo/client/react/ssr'
import { NextQueryParamProvider } from 'next-query-params'
import withApollo from 'next-with-apollo'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { CreateApolloReturn, createApollo } from '../apollo/client/createApollo'
import { ToastProvider } from '../features/toast/useToast'
import { TelemetryProvider } from '../features/tracing/providers/TelemetryProvider'
import { fixiOS100vhIssue } from '../styles/fixiOS100vh'
import { programmingCasesToTitleCase } from '../utilities/programmingCasesToTitleCase'
import { ReinitializableApolloProvider } from '../apollo/client/ReinitializableApolloProvider'
import { useSavePreviousPageAndScrollPositionAndRestoreScrollBeyondNextExperimental } from '../hooks/useSavePreviousPageAndScrollPositionAndRestoreScrollBeyondNextExperimental'
import getConfig from 'next/config'
import { CookieProvider } from '../hooks/useCookies'
import { SyncThemeOnClientSideChange } from '../features/upgrade-labs/atoms/SyncThemeOnClientSideChange'
import { TrackpageViews } from '../features/analytics/TrackPageViews'
import { immediatelySetReferralSourceInSessionStorage } from '../features/analytics/utils/immediatelySetReferralSourceInSessionStorage'
import '../generated/tailwind.css'

immediatelySetReferralSourceInSessionStorage()
filterConsole()
initReactAxe()
fixiOS100vhIssue()

type GetInitialPropsReturn = Awaited<Promise<PromiseLike<ReturnType<(typeof RealizeMeApp)['getInitialProps']>>>>
type Props = GetInitialPropsReturn & AppProps & { apollo: CreateApolloReturn }

const gtmId = getConfig()?.publicRuntimeConfig.publicEnvs?.GTM_ID

function RealizeMeApp({ Component, pageProps, userAgentIsMobile, apollo, cookieString }: Props) {
  const RecoilizeDebugger = useRecoilizeDebugger()
  const { pathname } = useRouter()
  const pageTitle = programmingCasesToTitleCase(pathname?.split('/').pop() ?? '') || 'Upgrade Health'

  useSavePreviousPageAndScrollPositionAndRestoreScrollBeyondNextExperimental()
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <ErrorBoundary>
        <NextQueryParamProvider>
          <RecoilRoot>
            <CookieProvider cookieString={cookieString}>
              <RecoilizeDebugger />
              <ReinitializableApolloProvider apollo={apollo}>
                <DeviceProvider userAgentIsMobile={userAgentIsMobile}>
                  <ToastProvider>
                    <AuthProvider>
                      <TelemetryProvider>
                        <MonitoringAndSyncing />
                        <SyncThemeOnClientSideChange />
                        <AppLayoutTemplate
                          modal={<Modal />}
                          sidebar={<Sidebar />}
                          header={<Navbar />}
                          footer={<Footer />}
                          body={<Component {...pageProps} />}
                        />
                        <TrackpageViews />
                        {!!gtmId && <GoogleTagManager gtmId={gtmId} />}
                      </TelemetryProvider>
                    </AuthProvider>
                  </ToastProvider>
                </DeviceProvider>
              </ReinitializableApolloProvider>
            </CookieProvider>
          </RecoilRoot>
        </NextQueryParamProvider>
      </ErrorBoundary>
    </>
  )
}

type UserAgentIsMobile = boolean | null

RealizeMeApp.getInitialProps = async ({ ctx, Component }: AppContext) => {
  // if we are on server, detect device based on request. otherwise, just use screen width calculation in the Provider
  // since we don't want is-mobile in client bundle, it is listed as false in the "browser" field of package.json
  // ua is user agent and we get it from thre req object, and tablet=true means count tablets as mobile
  const userAgentIsMobile: UserAgentIsMobile =
    typeof window === 'undefined' && ctx?.req ? require('is-mobile')({ ua: ctx.req, tablet: true }) : null

  const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {}
  const cookieString = ctx.req?.headers.cookie ?? ''
  return { userAgentIsMobile, pageProps, cookieString }
}

// @ts-ignore the types for this library seem wrong. they only allow getInitialProps to accept NextPageContext event
// though it will be AppContext when wrapping the entire App
export default withApollo(createApollo, { getDataFromTree })(RealizeMeApp)
