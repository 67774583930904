import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import * as React from 'react'
import type { Id } from 'react-toastify'
import { ButtonBase } from '../../common/components/ButtonBase'
import { useAutoUpdatingRef } from '../../hooks/useAutoUpdatingRef'
import { theme } from '../../styles/theme'
import { DebouncedFunc, debounce } from 'lodash'

const LazyToast = dynamic(() => import('./organisms/Toast').then((x) => x.Toast))

type Content = React.ReactNode
type Options = { cta?: { onClick: () => void; content: Content } }

type ToastFunc = (content?: Content, options?: Options) => Promise<Id>

type Context =
  | {
      setSuccessToast: DebouncedFunc<ToastFunc>
      setErrorToast: DebouncedFunc<ToastFunc>
      setNotificationToast: DebouncedFunc<ToastFunc>
    }
  | undefined
const ToastContext = React.createContext<Context>(undefined)

export function useToast() {
  const context = React.useContext(ToastContext)
  if (context === undefined) throw new Error("'useToast' must be used within a ToastProvider")
  return context
}

const ToastContent = styled.div<{ $hasCta?: boolean }>`
  display: flex;
  justify-content: space-between;
  font-family: ${theme.fonts.realizeMe};
`

const Cta = styled(ButtonBase)<{ $isError?: boolean; $isNotification?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  margin-left: 8px;
  text-decoration-line: underline;
  white-space: nowrap;
  color: ${(p) =>
    p.$isError ? theme.colors.socialist : p.$isNotification ? theme.colors.dodgerollGold : theme.colors.aegean};
`
const isBrowser = typeof window !== 'undefined'

export function ToastProvider({ children }: React.PropsWithChildren<{}>) {
  const [toastModuleLoaded, setToastModuleLoaded] = React.useState(false)

  const toastModuleLoadedRef = useAutoUpdatingRef(toastModuleLoaded)

  const value = React.useMemo(() => {
    async function setSuccessToast(content: Content, options?: Options) {
      const { toast } = await import('react-toastify')
      if (!toastModuleLoadedRef.current) setToastModuleLoaded(true)
      const jsx = (
        <ToastContent>
          {content ?? 'Success'}
          {!!options?.cta && <Cta onClick={options.cta.onClick}>{options.cta.content}</Cta>}
        </ToastContent>
      )
      if (isBrowser && 'vibrate' in navigator) navigator.vibrate?.([400])
      return toast.success(jsx)
    }

    async function setErrorToast(content: Content, options?: Options) {
      const { toast } = await import('react-toastify')
      if (!toastModuleLoadedRef.current) setToastModuleLoaded(true)
      const jsx = (
        <ToastContent>
          {content ?? 'Error'}
          {!!options?.cta && (
            <Cta $isError onClick={options.cta.onClick}>
              {options.cta.content}
            </Cta>
          )}
        </ToastContent>
      )
      if (isBrowser && 'vibrate' in navigator) navigator.vibrate?.([100, 50, 100])
      return toast.error(jsx)
    }

    async function setNotificationToast(content: Content, options?: Options) {
      const { toast } = await import('react-toastify')
      if (!toastModuleLoadedRef.current) setToastModuleLoaded(true)
      const jsx = (
        <ToastContent>
          {content ?? 'Alert'}
          {!!options?.cta && (
            <Cta $isNotification onClick={options.cta.onClick}>
              {options.cta.content}
            </Cta>
          )}
        </ToastContent>
      )
      if (isBrowser && 'vibrate' in navigator) navigator.vibrate?.([100, 50, 100])
      return toast(jsx)
    }
    return {
      setNotificationToast: debounce(setNotificationToast, 300),
      setErrorToast: debounce(setErrorToast, 300),
      setSuccessToast: debounce(setSuccessToast, 300),
    }
  }, [toastModuleLoadedRef])

  return (
    <ToastContext.Provider value={value}>
      {toastModuleLoaded && <LazyToast />}
      {children}
    </ToastContext.Provider>
  )
}
