import React from 'react'
import styled from '@emotion/styled'
import { CssProp } from '../../typescript/emotion'
import { transientOptions } from '../../common/style/transientOptions'
import { theme } from '../../styles/theme'
import { ButtonBase } from '../../common/components/ButtonBase'

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  cssProp?: CssProp
  loading?: boolean
}

const ConditionalButton = ({
  children,
  onClick,
  loading,
  disabled,
  cssProp,
  ...rest
}: React.PropsWithChildren<ButtonProps>) => {
  const eventsDisabled = loading || disabled
  return onClick ? (
    <ButtonBase
      onClick={onClick}
      aria-label={rest['aria-label'] || 'close'}
      $loading={loading}
      disabled={eventsDisabled}
      {...rest}
    >
      {children}
    </ButtonBase>
  ) : (
    <>
      {React.Children.map(children, (child) =>
        // @ts-ignore
        React.isValidElement(child) ? React.cloneElement(child, { css: cssProp, ...rest }) : null
      )}
    </>
  )
}

// !! this same effect is used in PillAddButton.tsx. if it gets changed here, it should be changed in both places
const hoverAnimateCss = (isPlus?: boolean) => /* css */ `
  :hover {
    transform: rotate(${isPlus ? 90 : 45}deg);
  }
  :active {
    transform: rotate(${isPlus ? 90 : 45}deg);
  }
`
const Svg = styled('svg', transientOptions)<{ $animateHover?: boolean; $requiresRotation?: boolean }>`
  transform: rotate(${(p) => (p.$requiresRotation ? 0 : -45)}deg);
  transition: transform 0.3s ease-out;

  ${(p) => (p.$animateHover ? hoverAnimateCss(p.$requiresRotation) : undefined)}
`

type BaseProps = {
  color?: string
  onClick?: () => void
  className?: string
  isPlus?: boolean
  size?: string | number
  circleBorderColor?: string
  hasCircleBorder?: boolean
  circleFillColor?: string
  animate?: boolean
  style?: React.CSSProperties
} & React.HTMLAttributes<HTMLButtonElement> &
  React.SVGProps<SVGSVGElement>

type CircleProps = BaseProps & {
  hasCircleBorder: boolean
  circleBorderColor?: string
  circleFillColor?: string
  crossThickness?: number | string
}
type NonCircleProps = BaseProps & {
  hasCircleBorder?: never
  circleBorderColor?: never
  circleFillColor?: never
}

export type CrossDerivativeProps = (CircleProps | NonCircleProps) & {
  buttonProps?: ButtonProps
  svgProps?: React.SVGAttributes<HTMLOrSVGElement>
}

// TODO the new circle svg we are using seems to have space built in between the svg and the actual content. see if we
// can export one which is just the content with no padding

export function CrossDerivative({
  color = theme.colors.grayMidnight,
  circleBorderColor,
  size,
  circleFillColor,
  onClick,
  className,
  hasCircleBorder,
  isPlus,
  style,
  animate,
  buttonProps,
  svgProps,
  ...rest
}: CrossDerivativeProps) {
  const finalSize = size ? size : hasCircleBorder ? 44 : 24

  // TODO look into animate=false not working
  // The svgs we are using are different (X vs +) depending on if it is the plain one, or the one with a circle border
  const requiresRotation = hasCircleBorder ? isPlus : !isPlus
  const crossThickness = 'crossThickness' in rest && rest.crossThickness ? rest.crossThickness : 1.5
  return (
    <ConditionalButton onClick={onClick} className={className} style={style} {...buttonProps}>
      {hasCircleBorder ? (
        <Svg
          fill="none"
          $requiresRotation={requiresRotation}
          width={finalSize}
          height={finalSize}
          viewBox="0 0 44 44"
          $animateHover={typeof animate === 'boolean' ? animate : !!onClick}
          {...svgProps}
        >
          <g>
            <rect x="7" y="7" width="30" height="30" rx="15" fill={circleFillColor || 'none'} />
            <line
              x1="22"
              y1="16.75"
              x2="22"
              y2="27.25"
              stroke={color || theme.colors.grayMidnight}
              strokeWidth={crossThickness}
              strokeLinecap="round"
            />
            <line
              x1="27.25"
              y1="22"
              x2="16.75"
              y2="22"
              stroke={color || theme.colors.grayMidnight}
              strokeWidth={crossThickness}
              strokeLinecap="round"
            />
            <rect
              x="7.5"
              y="7.5"
              width="29"
              height="29"
              rx="14.5"
              stroke={circleBorderColor || circleFillColor || color}
            />
          </g>
          <defs>
            <filter
              id="filter0_d_387_9997"
              x="0"
              y="0"
              width="44"
              height="44"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="3.5" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.00375536 0 0 0 0 0.07973 0 0 0 0 0.383629 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_387_9997" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_387_9997" result="shape" />
            </filter>
          </defs>
        </Svg>
      ) : (
        <Svg
          width={finalSize}
          height={finalSize}
          viewBox="0 0 24 24"
          $animateHover={!!onClick}
          $requiresRotation={requiresRotation}
        >
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95a1 1 0 0 1 1.414 1.414L13.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414L12 13.414l-4.95 4.95a1 1 0 0 1-1.414-1.414l4.95-4.95-4.95-4.95A1 1 0 0 1 7.05 5.636l4.95 4.95z"
              fill={color}
            />
          </g>
        </Svg>
      )}
    </ConditionalButton>
  )
}
