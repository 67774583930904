import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import { CreateApollo, CreateApolloOptions, CreateApolloReturn, createApollo } from './createApollo'
import { useDevTools } from '../../hooks/useDevTools'
import { usePrevious } from '../../hooks/usePrevious'
import {
  appendUserIdToClientAdminWsEndpoint,
  clientNonAdminWsEndpoint,
} from '../../common/constants/envDependentRoutes'

type ContextValue = { reinitApolloClient: (options?: CreateApolloOptions) => void } | undefined

const Context = React.createContext<ContextValue>(undefined)

export function ReinitializableApolloProvider({
  apollo,
  children,
}: React.PropsWithChildren<{ apollo: CreateApolloReturn }>) {
  const [currentClient, setCurrentClient] = React.useState(apollo)

  const [{ apiAccountOverride }] = useDevTools()

  const accountOverrideId = apiAccountOverride?.id

  const reinitApolloClient = React.useCallback(
    (clientOptions?: Parameters<CreateApollo>[0]) => {
      return setCurrentClient(
        createApollo({
          wsUrl: accountOverrideId ? appendUserIdToClientAdminWsEndpoint(accountOverrideId) : clientNonAdminWsEndpoint,
          ...clientOptions,
        })
      )
    },
    [accountOverrideId]
  )

  const previousApiAccountOverrideId = usePrevious(accountOverrideId)
  React.useEffect(() => {
    if (accountOverrideId !== previousApiAccountOverrideId) {
      reinitApolloClient()
    }
  }, [accountOverrideId, previousApiAccountOverrideId, reinitApolloClient])
  return (
    <Context.Provider value={{ reinitApolloClient }}>
      <ApolloProvider client={currentClient}>{children}</ApolloProvider>
    </Context.Provider>
  )
}

export const useReinitApolloClient = () => {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error("'useReinitializeApolloClient' must be used within a ReinitializableApolloProvider")
  }
  return context
}
