import * as React from 'react'
import { merge } from 'lodash'
import { xHasuraRoleHeader } from '../../../../common/constants/headers'
import { Role } from '../../../../common/constants/roles'
import { RoleContext } from '../../../../features/auth/providers/RoleProvider'

export function useRoleOptions<T>(options: T): T {
  const role = React.useContext(RoleContext)

  if (!role || [Role.anonymous, Role.user].includes(role)) {
    // !!adding x-hasura-* headers break requests that go directly to nest/hasura, so don't add the hasura role header
    // here unless you strip it from the apollo links which don't hit our graph pass through (nonadmin links at the time
    // of writing)
    return options
  }
  return merge(
    {
      context: {
        headers: {
          [xHasuraRoleHeader]: role,
        },
      },
    },
    // override fetchPolicy which will likely be set as cache-and-network
    { ...options, fetchPolicy: 'no-cache' }
  )
}
