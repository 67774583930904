import * as React from 'react'
import { useRouter } from 'next/router'
import { useSharedFacebookConversionDataRef } from './hooks/useSharedFacebookConversionDataRef'
import { shopLabsRoute, shopMedicationsRoute, shopRoute, shopSupplementsRoute } from '../../common/constants/routes'
import { sendFacebookConversionEventFromClient } from './sendFacebookConversionEventFromClient'
import { analytics } from './analytics'

const viewedContentFacebookPages = [shopRoute, shopLabsRoute, shopSupplementsRoute, shopMedicationsRoute]

const viewedContentFacebookPageRegexes = [
  new RegExp(`${shopMedicationsRoute}\\/[a-zA-Z0-9-_]+`),
  new RegExp(`${shopSupplementsRoute}\\/[a-zA-Z0-9-_]+`),
]

function useTrackPageViews() {
  const { events } = useRouter()
  const sharedFacebookConversionDataRef = useSharedFacebookConversionDataRef()
  React.useEffect(() => {
    const onRouteChangeComplete = (url: string) => {
      analytics.track(`Page View - ${document.title}`, {
        page: location.pathname,
        title: document.title,
        search: location.search,
        url: location.href,
      })

      if (
        viewedContentFacebookPages.some((x) => url.split('?')[0] === x) ||
        viewedContentFacebookPageRegexes.some((x) => x.test(url))
      ) {
        sendFacebookConversionEventFromClient({ ...sharedFacebookConversionDataRef.current, event_name: 'ViewContent' })
      }
    }
    onRouteChangeComplete(window.location.href)
    events.on('routeChangeComplete', onRouteChangeComplete)
    return () => {
      events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [events, sharedFacebookConversionDataRef])
}

export function TrackpageViews() {
  useTrackPageViews()
  return null
}
