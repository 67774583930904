import * as React from 'react'
import { isUpgradeLabsUserCookieName } from '../../../common/constants/cookies'
import { useCookies } from '../../../hooks/useCookies'
import { featureFlags } from '../FeatureFlagProvider'
import { useFeatureFlag } from './useFeatureFlag'

export function useSyncIsUpgradeLabsUserToCookies() {
  const { isEnabled: isUpgradeLabsUser, featureFlagsReady } = useFeatureFlag(featureFlags.isUpgradeLabsUser)

  const { set, remove } = useCookies()
  React.useEffect(() => {
    if (!featureFlagsReady || typeof window === 'undefined') return
    if (isUpgradeLabsUser) {
      set(isUpgradeLabsUserCookieName, 'true')
    } else {
      remove(isUpgradeLabsUserCookieName)
    }
  }, [set, remove, isUpgradeLabsUser, featureFlagsReady])
}
