import { homeRoute } from '../../../common/constants/routes'
import { NextLink } from '../../../common/functionality/NextLink'
import styled from '@emotion/styled'
import { useIsUpgradeLabsUser } from '../../upgrade-labs/hooks/useIsUpgradeLabsUser'
import { transientOptions } from '../../../common/style/transientOptions'
import { NextImage } from '../../../common/functionality/NextImage'
import { cdnUrl } from '../../../common/constants/s3ImageUrls'
import { notebookDownMq } from '../../../styles/mediaQueries'

const Container = styled.div`
  padding: var(--Numbers-8, 8px) var(--Numbers-2, 2px);
`

const ImageContainer = styled.div`
  position: relative;
  width: 172px;
  height: 22px;

  ${notebookDownMq} {
    width: 141px;
    height: 22.6px;
  }
`

const Link = styled(NextLink, transientOptions)<{ $isUpgradeLabsUser: boolean }>`
  align-self: stretch;
  display: flex;
  align-items: center;
`

export function HomeButton() {
  const { isUpgradeLabsUser } = useIsUpgradeLabsUser()
  const href = homeRoute

  return (
    <Link href={href} $isUpgradeLabsUser={isUpgradeLabsUser}>
      <Container>
        <ImageContainer>
          <NextImage
            fill
            src={cdnUrl('upgrade-labs/upgrade-labs-health-platform-logo.png')}
            style={{ mixBlendMode: 'screen' }}
            alt={'upgrade labs health platform'}
          />
        </ImageContainer>
      </Container>
    </Link>
  )
}
