import React from 'react';
import { usePublicEnv } from '../../../hooks/usePublicEnv';
import { initInstrumentation } from '../../../otel/instrumentation.client';


export function TelemetryProvider({children}: React.PropsWithChildren) {
  const { HONEYCOMB_API_KEY } = usePublicEnv()
  const isClient = process.env.NEXT_RUNTIME !== 'nodejs';

  React.useEffect(() => {
    if (isClient && HONEYCOMB_API_KEY) {
      initInstrumentation(HONEYCOMB_API_KEY);
    }
  }, [isClient, HONEYCOMB_API_KEY]); 
  return <>{children}</>;
}
