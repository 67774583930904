// !! allow hook imports in this file exclusively as this is where we are importing them to reexport the ones we use in
// the app
import * as Apollo from '@apollo/client' // eslint-disable-line no-restricted-imports
import { DocumentNode } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'
import { writeQueryResponseToDiskApiRoute } from '../../../common/constants/routes'
import { useAirplaneModeMock } from '../../../hooks/useAirplaneModeMock'
import { useDevTools } from '../../../hooks/useDevTools'
import { restClient } from '../../../utilities/restClient'
import { useAddDefaultErrorHandling } from '../../utils/useAddDefaultErrorHandling'
import { defaultSubscriptionOptionsToResubscribe } from './utils/defaultSubscriptionOptionsToResubscribe'
import { defaultQueriesToCacheAndNetwork } from './utils/defaultQueriesToCacheAndNetwork'
import { useSkipNonWhitelistedUnauthenticatedRequests } from './utils/useSkipNonWhitelistedUnauthenticatedRequests'
import { useRoleOptions } from './utils/useRoleOptions'
export type {
  LazyQueryHookOptions,
  MutationHookOptions,
  QueryHookOptions,
  SubscriptionHookOptions,
  SuspenseQueryHookOptions,
} from '@apollo/client'

// !! anything added in this file will only available
// !!   1) when using apollo hooks as opposed to when calling the client directly
// !!   2) when using certain elements of the hooks such as when adding refetchQueries to the hook options or when
// calling a mutation with additional options at the callsite. in those cases, context or options added here may not get
// passed through to the eventual call. moving forward, it is possible we will neeed to move all of this logic into
// module-setters which we then place inside links so we are absolutely certain that any logic is applied uniformly
// throughout all apollo calls as opposed to just the most common hook usage patterns. however, the module setting is
// somewhat of an anti-pattern so only use if 100% necessary (such as with the auth context in our authlink)
/* Need these types exported for codegen to consume */

// TODO a lot of this work is specific to each operation type. separate these additional options out in a switch which
// initially adds all common mods and then sends the function off to specific overide sections specific to the operation
// type

/**
 * Wraps apollo hooks with correct role headers, context, and any other non-native data used
 * @param fn
 */
function customHookWrapper<T extends (...args: any[]) => any>(fn: T): T {
  return <T>function (documentNode: DocumentNode, options: Parameters<T>[1] = {}): ReturnType<T> {
    useAddDefaultErrorHandling({ documentNode, options })

    const optionsWithQueryFetchPolicyDefault = defaultQueriesToCacheAndNetwork(options, documentNode)
    const optionsWithSubscriptionDefaults = defaultSubscriptionOptionsToResubscribe(
      optionsWithQueryFetchPolicyDefault,
      documentNode
    )

    const [{ writeQueryResponseToDisk }] = useDevTools()
    const { mockResponse } = useAirplaneModeMock(documentNode, options.variables)
    const roleHookOptions = useRoleOptions(optionsWithSubscriptionDefaults)

    const optionsWithNonWhitelistedUnauthedRequestsSkipped = useSkipNonWhitelistedUnauthenticatedRequests(
      documentNode,
      roleHookOptions
    )
    if (mockResponse) optionsWithNonWhitelistedUnauthedRequestsSkipped.skip = true

    const res = fn(documentNode, optionsWithNonWhitelistedUnauthedRequestsSkipped)

    if (mockResponse) res.data = mockResponse

    if (writeQueryResponseToDisk && !res.error) {
      restClient(writeQueryResponseToDiskApiRoute, {
        body: { data: res.data, name: getOperationName(documentNode), variables: options.variables },
      })
    }
    return res
  }
}

export const useLazyQuery: typeof Apollo.useLazyQuery = customHookWrapper(Apollo.useLazyQuery)
export const useMutation: typeof Apollo.useMutation = customHookWrapper(Apollo.useMutation)
export const useQuery: typeof Apollo.useQuery = customHookWrapper(Apollo.useQuery)
export const useSubscription: typeof Apollo.useSubscription = customHookWrapper(Apollo.useSubscription)
export const useSuspenseQuery: typeof Apollo.useSuspenseQuery = customHookWrapper(Apollo.useSuspenseQuery)
export const useFragment: typeof Apollo.useFragment = customHookWrapper(Apollo.useFragment)
export const useReadQuery: typeof Apollo.useReadQuery = customHookWrapper(Apollo.useReadQuery)
export const useBackgroundQuery: typeof Apollo.useBackgroundQuery = customHookWrapper(Apollo.useBackgroundQuery)
export const useReactiveVar: typeof Apollo.useReactiveVar = customHookWrapper(Apollo.useReactiveVar)
