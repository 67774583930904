import * as React from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

const RecoilizeDebugger = dynamic(() => import('recoilize'), { ssr: false })

export function useRecoilizeDebugger() {
  const { query } = useRouter()
  const [root, setRoot] = React.useState<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const initRecoilize =
      typeof window !== 'undefined' &&
      // this package currently crashes. waiting for a patch (possibly
      // https://github.com/open-source-labs/Recoilize/pull/172 when it is merged), but until then, no need to run it
      (process.env.NODE_ENV !== 'production' || query.recoilize === 'true') &&
      false

    if (initRecoilize) {
      const rootElt = document.getElementById('__next') as HTMLDivElement | null
      if (rootElt) setRoot(rootElt)
    }
  }, [root, query])

  const RecoilizeDebuggerComponent = React.useCallback(() => (root ? <RecoilizeDebugger root={root} /> : null), [root])

  return RecoilizeDebuggerComponent
}
