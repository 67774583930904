import styled from '@emotion/styled'
import { useCountdown } from '../../../hooks/useCountdown'
import { useLogInState } from '../recoil'
import { useLogOut } from '../hooks/useLogOut'
import { useAccount } from '../hooks/useAccount'
import { theme } from '../../../styles/theme'
import { UpgradeHealthLogo } from '../../../assets/components/UpgradeHealthLogo'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${theme.colors.grayCoal};
  flex-direction: column;
  color: ${theme.colors.grayPorcelain};
  padding-top: 20vw;
`

const Text = styled.div`
  margin-top: 40px;
`

const RedirectText = styled.div`
  margin-top: 5px;
  font-size: 12px;
`

export function LoggingInContent() {
  const { error, isAuthenticated, isLoading } = useAccount()

  const [, setLogInState] = useLogInState()
  const logout = useLogOut()

  const { countdown } = useCountdown({
    startCountdown: !!error,
    countdownTime: 3,
    onComplete: () => {
      setLogInState((s) => ({ ...s, isLoggingIn: false }))
      logout()
    },
  })

  return (
    <Container>
      <UpgradeHealthLogo size={125} color={theme.colors.grayPorcelain} />
      {isLoading ? (
        <Text>loading...</Text>
      ) : !isAuthenticated && !error ? (
        <Text>Redirecting to authentication...</Text>
      ) : error ? (
        <>
          <Text>Error logging in.</Text>
          <RedirectText>Redirecting in {countdown}.</RedirectText>
        </>
      ) : null}
    </Container>
  )
}
