import * as React from 'react'

export async function initReactAxe() {
  if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    const [ReactDOM, axe] = await Promise.all([
      import('react-dom').then((x) => x.default),
      import('@axe-core/react').then((x) => x.default),
    ])
    axe(React, ReactDOM, 1000)
  }
}
